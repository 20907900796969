<template>
	<div>
		<div>
			<img :src="signImg ? signImg : img" alt="" style="width: 100vw;height: calc(100vh - 60px);">
		</div>
		<div style="position: fixed;top: 0;right: 0;width: 100vw;height: 100vh;z-index: 9999;">
			<canvas ref="signHandle" class="canvas" style="border: 1px solid #ccc;box-sizing: border-box;"></canvas>
			<div style="text-align: center;height: 55px;background-color: #fff;margin-top: 5px;">
				<el-button size="small" @click="closeHandle">取消</el-button>
				<el-button size="small" @click="clearHandle">清空</el-button>
				<el-button size="small" type="primary" v-if="useSing" @click="ClickUseSing">使用预制签名</el-button>
				<el-button size="small" @click="onComfirm" type="primary" :disabled="changeNum === 0">确认</el-button>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		showSign: {
			default: false,
			require: false,
			type: Boolean
		},
		useSing: {
			default: true,
			require: false,
			type: Boolean
		}
	},
	name: "sign",
	data() {
		return {
			img: require(`../../assets/temImage.png`),
			signImg: "",
			el: '', // canvas dom
			ctx: '', // canvas context
			clientHeight: document.documentElement.clientHeight,
			clientWidth: document.documentElement.clientWidth,
			changeNum: 0
		}
	},
	computed: {
		captchaSrc() {
			if (this.nameBG) return 'data:image/jpeg;base64,' + this.nameBG.replace(/[\r\n]/g, "")
			else return ''
		}
	},
	mounted() {
		window.onresize = () => {
			this.clientHeight = document.documentElement.clientHeight - 2
			this.clientWidth = document.documentElement.clientWidth
			this.draw()
			return
		}
		this.signImg = window.localStorage.getItem('signImg') || ''
		this.$nextTick(() => {
			this.draw()
		})
		console.log(this.changeNum)
	},
	methods: {
		ClickUseSing() {
			let EuInfo = JSON.parse(window.localStorage.getItem('EuInfo')) || JSON.parse(window.localStorage.getItem('RGInfo')) || {}
			console.log(EuInfo)
			if (EuInfo.NameSignature) {
				this.$emit('success', EuInfo.NameSignature);
			} else {
				this.$phoneMessage({
					message: "暂无预制签名"
				})
			}

		},
		closeHandle() {
			this.$emit('close')
		},
		// 添加绘制 line
		draw() {
			this.$refs.signHandle.addEventListener(
				'touchstart',
				e => e.preventDefault(),
				{
					passive: false
				}
			)
			this.initCanvas()
		},
		// 初始化canvas配置
		initCanvas() {
			this.$refs.signHandle.width = this.clientWidth - 2
			this.$refs.signHandle.height = this.clientHeight - 60
			this.ctx = this.$refs.signHandle.getContext('2d')
			this.setCanvas()
			this.drawStart()
			this.drawing()
			this.drawEnd()
			setTimeout(() => {
				this.changeNum = 0
			})
		},
		// 配置 canvas
		setCanvas() {
			const { ctx } = this
			// 设置背景色
			ctx.fillStyle = 'transparent'
			ctx.fillRect(0, 0, this.clientWidth - 2, this.clientHeight - 60)

			// 设置线条颜色
			ctx.strokeStyle = '#000'
			// 设置线宽
			ctx.lineWidth = 4
			// 设置线条两头的结束点和开始点是圆形的
			ctx.lineCap = 'round'
			ctx.lineJoin = 'bevel'
		},
		// 开始绘制
		drawStart() {
			const { ctx } = this
			this.$refs.signHandle.addEventListener(
				'touchstart',
				e => {
					this.changeNum++
					ctx.beginPath()
					ctx.moveTo(e.changedTouches[0].pageX, e.changedTouches[0].pageY)
				},
				false
			)

		},
		// 绘制中
		drawing() {
			const { ctx } = this
			this.$refs.signHandle.addEventListener(
				'touchmove',
				e => {
					ctx.lineTo(e.changedTouches[0].pageX, e.changedTouches[0].pageY)
					ctx.stroke()
				},
				false
			)
		},
		// 绘制结束
		drawEnd() {
			console.log(11111)
			const { ctx } = this
			this.$refs.signHandle.addEventListener('touchend', () => ctx.closePath(), false)

		},
		// 清空
		clearHandle() {
			console.log('清空')
			this.changeNum = 0
			this.initCanvas()

		},
		onComfirm() {
			this.saveImg()
		},
		// 保存信息
		saveImg() {
			var tempCanvas = document.createElement('canvas');
			var newctx = tempCanvas.getContext('2d');
			tempCanvas.width = this.clientHeight - 60
			tempCanvas.height = this.clientWidth - 2,
				// 先将原canvas内容旋转90度后绘制到tempCanvas上
				newctx.rotate((-90 * Math.PI) / 180);
			newctx.drawImage(this.$refs.signHandle, -this.$refs.signHandle.width, 0, this.$refs.signHandle.width, this.$refs.signHandle.height);
			// const imgBase64 = this.$refs.signHandle.toDataURL()
			const imgBase64 = tempCanvas.toDataURL('image/png');
			console.log(imgBase64)
			this.$emit('success', imgBase64);
		}

	},

	watch: {
		changeNum(val) {
			console.log(val, 'val')
		}
	}
}
</script>

<style scoped></style>